<template>
  <div id="profile-page">

    <Profile 
        v-if="user" 
        :urlId="user"
    />

    <Footer v-show="!loading"/>

    <div v-show="loading" class="p-f w-100 h-100 d-f ai-c">
      <marquee-text :repeat="6" :paused="false">

        <h1 class="ws-pre tt-up text-outline">
          Loading
        </h1>

      </marquee-text>
    </div>

    


  </div>
</template>

<script>
import { auth, rtdb } from '@/db.js'
import { mapGetters } from "vuex"
import Profile from '../components/profile/Profile.vue'
import MarqueeText from 'vue-marquee-text-component'
import Footer from '@/components/navigation/Footer.vue'

export default {
  name: 'ProfilePage',
  components: {
    Profile,
    Footer,
    MarqueeText
  },
  data() {
      return {
          user: null,
          loading: false
      }
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
        const urlId = this.$route.params.id
        const encodedUsername = encodeURIComponent(urlId).replace(/\./g, '%2E')
        const ref = rtdb.ref()
        this.loading = true
        ref.child("userSettings")
        .orderByChild("usernameUrl")
        .equalTo(encodedUsername)
        .once("value").then((snap)=>{
            this.user = snap.val()
        }).then(() => {
            this.loading = false
        })
        .catch((err) => {
            console.log(err);
        })
    }
  }
}
</script>
