<template>
<div id="profile" class="big-m-t">

  <!-- {{profile}} -->

  <div class="d-f fd-c ai-c">

    <h1 class="small-m-b">{{profile.username}}</h1>
    <div class="w-100 d-f ai-c">
      <div class="p-a w-100">
        <marquee-text :repeat="12" :paused="false">

          <h1 class="ws-pre tt-up text-outline">
            <span class="m-r" v-if="locsRank">{{locsRank.slice(-1)[0].loc}} #{{locsRank.slice(-1)[0].rank}} </span>

            <!-- <span v-if="locsRank">
              <span class="m-r" v-for="x in locsRank" :key="x.id">
                  {{x.loc}} #{{x.rank}}
              </span>
            </span> -->

            <span v-else>Unranked artist </span>
          </h1>
          <!-- <h1 class="ws-pre tt-up">
            <div v-if="locsRank">
              <span>#{{locsRank.slice(-1)[0].rank}} </span>
              <span class="text-outline">{{locsRank.slice(-1)[0].loc}} </span>
            </div>
            <span v-else>Unranked artist </span>
          </h1> -->

        </marquee-text>
      </div>
      <div class="d-f jc-c w-100 img-big">
        <!-- <img :src="getImg(profile.profileImg)" alt=""> -->
        <img class="b-r-100" v-if="profileImg" :src="profileImg"/>
        <div v-else><PlaceholderImg/></div>
      </div>

    </div>

    <!-- <h1 class="tt-up text-link">Play</h1> -->

    <SongsPlaylistProfile
      :playlist-position="0" 
      @play-select-song="playSelectedSongAsync" 
      :playlist="uploadsList">
    </SongsPlaylistProfile>

    

  </div>

  <!-- <div class="p-f b-0 w-100 d-f jc-sb ai-fe big-m-b tt-up p-half bs-bb"> -->
  <div class="p-f b-0 l-0 p" :class="{ playermargin: !getPlayerHidden  }">
    <div class="d-f fd-c tt-up">
      <p class="grey">{{profile.username}}</p>
      <div class="d-f fd-c m-l">
        <a @click="$store.dispatch('togglePopMess')">Follow</a>
        <a @click="$store.dispatch('togglePopMess')">Followers</a>
        <a @click="$store.dispatch('togglePopMess')">Following</a>
      </div>

      <!-- <p class="grey">Rank</p>
      <div v-if="locsRank">
        <div class="d-f fd-c m-l" v-for="(x, i) in locsRank.slice().reverse()" :key="i">
          <a>
            <span>#{{x.rank}} </span> 
            <router-link :to="{ path: `artists/${x.loc}` }">{{x.loc}}</router-link>
          </a>
        </div>
      </div>
      <div class="d-f fd-c m-l" v-else><p>Unranked</p></div> -->
      
      <p class="grey">Info</p>
      <div class="d-f fd-c m-l">
        <p>Member nr. {{profile.member}}</p>
      </div>

      <div v-show="getLinks">
      <p class="grey">Links</p>
      <div class="d-f fd-c m-l" v-for="(val, key, i) in getLinks" :key="i">
        <a :href="val" target="_blank">{{key}}</a>
      </div>
      </div>

    </div>
  </div>

  <div class="p-f b-0 r-0" :class="{ playermargin: !getPlayerHidden  }">
    <!-- <div class="d-f fd-c p tt-up">
      <a @click="$store.dispatch('togglePopMess')"><span class="grey">+</span>Give some credd...</a>
    </div> -->
    <div class="d-f fd-c ai-fe p tt-up">
      <p class="grey">Rank</p>
      <div v-if="locsRank">
        <div class="d-f fd-c ai-fe m-r" v-for="(x, i) in locsRank.slice().reverse()" :key="i">
          <a>
            <router-link :to="{ path: `artists/${x.loc}` }">{{x.loc}}</router-link>
            <span> #{{x.rank}}</span>
          </a>
        </div>
      </div>
      <div class="d-f fd-c m-l" v-else><p>Unranked</p></div>
    </div>
    
  </div>

  <!-- {{activePlayer}} -->


</div>
</template>

<script>
import { rtdb } from '@/db.js'
import { mapGetters, mapActions } from "vuex"
import PlayerMixin from '@/mixins/PlayerMixin'
import SongsPlaylistProfile from '@/components/profile/SongsPlaylistProfile'
import MarqueeText from 'vue-marquee-text-component'
import PlaceholderImg from '@/assets/icons/PlaceholderImg.vue'

export default {
  name: 'Profile',
  props: ['urlId'],
  components: {
    SongsPlaylistProfile,
    MarqueeText,
    PlaceholderImg
  },
  mixins: [PlayerMixin],
  data() {
	  return {
      uploadsList: {
        title: 'Profile music jahdkjwah',
        songs: null
      },
      locsRank: null
	  }
  },
  mounted() {
    this.getUploads()
    this.getProfileRank()
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings',
      'getPlayerHidden'
    ]), 
    profile() {
      return Object.values(this.urlId)['0']
    },
    profileImg() {
      if(Object.values(this.urlId)['0'].profileImg) {
        return Object.values(this.urlId)['0'].profileImg
      }
      else {
        return false
      }
    },
    getLinks() {
      if(Object.values(this.urlId)['0'].links) {
        return Object.values(this.urlId)['0'].links
      } else {
        return false
      }
    }
  },
  methods:{
    ...mapActions(['addPlaylist', 'replacePlaylist']),

    playSelectedSongAsync(payload, playlist) {
      const that = this
      async function asyncFunc() {
        await that.replacePlaylist({
            title: 'Teeeeeesting',
            songs: that.uploadsList.songs
        })
        that.playSelectedSong(payload)
        console.log(payload);
      }
      return asyncFunc()
    },

    getUploads() {
      const userUid = Object.keys(this.urlId)['0']
      const usersMusicRef = rtdb.ref(`userMusic/${userUid}`)
      usersMusicRef.on('value', (snapshot) => {
        const arr = []
        snapshot.forEach(x => {
            arr.push( x.val() )
        })
        this.uploadsList.songs = arr
      })
    },
    getImg(x) {
      if (x) {
          return x
      } else {
          return 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1200px-No-Image-Placeholder.svg.png'
      }
    },
    getProfileRank() {
      const userUid = Object.keys(this.urlId)['0']
      const rankLocationsRef = rtdb.ref(`rankLocations/rankProfiles/allUsers`)
      const profileLocations = Object.values(this.profile.locations)
      const localArr = []
      profileLocations.forEach(x => {     
        rankLocationsRef
        .child(x)
        .orderByChild('key')
        .equalTo(userUid)
        .once('value', function(snapshot){
            snapshot.forEach(function(data) {
                const rank = parseInt(data.key) + 1
                localArr.push({
                  loc: x,
                  rank: rank
                })
            });
        });
      });
      this.locsRank = localArr
      
    },
    
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#profile {
  .playermargin {
    margin-bottom: 70px;
  }
}
</style>